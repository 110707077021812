import { createSlice } from "@reduxjs/toolkit";
import { useSelector } from "react-redux";
import { loadCalculation } from "./load-calculation";

export const fieldsSlice = createSlice({
    name: "calculation/fields",
    initialState: {},
    reducers: {
        setField: {
            prepare: (field, value) => ({
                payload: { field, value },
                meta: { dirty: field == "tableView" ? false : true },
            }),
            reducer: (state, { payload: { field, value } }) => {
                state[field] = value;

                if (
                    [
                        "marginParticipants",
                    ].includes(field)
                ) {
                    if ("" === value) {
                        state[field] = "1";
                        value = 1;
                    } else {
                        value = parseInt(value);
                    }
                }

                let paxMin;
                let paxMax;

                function getMinMaxParticipants(participantsRange) {
                    let paxMin = 1;
                    let paxMax = 1;
                    let pax = [];

                    participantsRange.split(",").forEach((participantsRangePartsGroup) => {

                        let participantsRangePartsGroups = participantsRangePartsGroup.split("-");

                        pax.push(participantsRangePartsGroups[0]);

                        if (participantsRangePartsGroups.length >= 2){
                            pax.push(participantsRangePartsGroups[1]);
                        }
                    });

                    let paxSorted = pax.sort((a, b) => a - b);

                    paxMin = paxSorted[0];
                    paxMax = paxSorted.pop();

                    return [paxMin, paxMax];
                }

                switch (field) {
                    case "marginParticipants":
                        [paxMin, paxMax] = getMinMaxParticipants(state.participantsRange);

                        if (value > parseInt(paxMax)) {
                            state.marginParticipants = paxMax;
                        } else if (value < parseInt(paxMin)) {
                            state.marginParticipants = paxMin;
                        }
                        break;

                    case "dateRanges":
                        state.dateRanges = value;
                        break;

                    case "participantsRange":
                        [paxMin, paxMax] = getMinMaxParticipants(state.participantsRange);

                        // case "minParticipants":
                        if (paxMin > parseInt(state.marginParticipants)) {
                            state.marginParticipants = paxMin;
                        }

                        // case "maxParticipants":
                        if (paxMax < parseInt(state.marginParticipants)) {
                            state.marginParticipants = paxMax;
                        }

                    break;
                }
            },
        },
        clearFields: ({ marginTax, organization }) => ({
            marginTax,
            organization,
        }),
    },
    extraReducers: (builder) =>
        builder
        .addCase(loadCalculation, (state, { payload }) => {
            const participantsRange = payload.participantsRange || 1;
            const marginParticipants =
                parseInt(payload.marginParticipants) || 1;

            state.itinerary = payload.itinerary.id;
            state.marginTax = state.marginTax ?? payload.marginTax;
            state.organization = state.organization ?? payload.organization;
            state.id = payload.id;
            state.active = payload.active;
            state.dateRanges = (
                payload.dateRanges ?? []
            ).map(({ fromDate, toDate }) => ({ fromDate, toDate }));

            state.participantsRange = "" + participantsRange;
            state.marginParticipants = marginParticipants;

            state.daysCount = "" + (parseInt(payload.daysCount) || 1);
            state.dynamic = !!payload.dynamic;
            state.note = payload.note ?? "";
            state.currency = payload.currency ?? "EUR";
            state.dateOverride = payload.dateOverride;
            state.duration = payload.duration;
            state.tableView = !!payload.dynamic ? "non-collapsed" : "collapsed";
            state.notes = payload.notes;
        })
});

export const getField = (field) => (state) =>
    state.calculation.fields[field] ?? "";
export const useField = (field) => useSelector(getField(field));
export const getAllFields = (state) => state.calculation.fields;

export const { setField, clearFields } = fieldsSlice.actions;

const fields = fieldsSlice.reducer;
export default fields;
