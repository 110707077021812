import React, {useContext, useEffect} from "react";
import {useDispatch} from "react-redux";
import {setField, useField} from "../store/fields-slice";
import Parameters from "./parameters";
import {SaveButton} from "./save-button";
import CalculationTable from "./table";
import Dates from "./dates";
import {useCalculationDetails} from "../store/calculation-details";
import {makeClean, useDirty, useView} from "../store/state-slice";
import {ErrorBoundary} from "./error-boundary";
import updateDetails from "../store/update-details";
import CalculationContext from "../context";
import {Placeholder} from "rsuite";
import {useOrganizationQuery} from "../../../store/api";
import Configurator from "./configurator";
import { loadCalculation } from "../store/load-calculation";
import { updateManualReload } from "../store/state-slice";
import { useGetCalculationsQuery } from "../../../store/api";
import Action from "./action";
import {Container} from "aurelia-framework";
import {WorkflowActionHandler} from "../../../action/handlers/workflow.action-handler";
import { toggleUseOverrideForAllPax } from "../store/choices-slice";

const workflowActionHandler = Container.instance.get(WorkflowActionHandler)

export default function PanelBody() {
    const dispatch = useDispatch()
    const {i18n, itinerary} = useContext(CalculationContext)
    const { isFetching, data, error, fulfilledTimeStamp, status, requestId} = useCalculationDetails()
    const { refetch } = useGetCalculationsQuery(itinerary)
    const notes = useField("notes")
    const id = useField("id")
    const {organization, hasMarginTax} = useContext(CalculationContext)
    const {isLoading} = useOrganizationQuery(organization)
    const dirty = useDirty()
    const note = useField("note")
    const view = useView()

    useEffect(
        () => {
            if ("fulfilled" === status) {
                dispatch(updateDetails(data))
            }
        },
        [status, requestId, fulfilledTimeStamp]
    )

    if (isLoading) {
        return (
            <div className="panel-body">
                <Placeholder.Grid active/>
            </div>
        )
    }

    if (error) {
        console.error(error)
        return (
            <div className="panel-body">
                <div className="alert alert-danger">
                    <p>
                        <span className="fa fa-triangle-exclamation"/>
                        {" "}
                        <strong>{i18n.tr(error.data?.message ?? "Ein Fehler ist aufgetreten.")}</strong>
                    </p>
                    {(error.data?.errors?.errors ?? []).map(e => <p key={e}>{i18n.tr(e)}</p>)}
                </div>
            </div>
        )
    }
    const {data: calculations = [],  refetch: refetchCalculations, isSuccess} = useGetCalculationsQuery(itinerary, {
        refetchOnMountOrArgChange: true,
        skip: false
    })
    useEffect(() => {
        if(view === "entries"){
            refetchCalculations()
        }
    }, [view])
    const dynamic = useField("dynamic");
    useEffect(() => {
        let calculation = new URLSearchParams(document.location.search.substring(1)).get('calculation')
        if (calculation) {
            calculation = calculations.find(({id}) => id === calculation)
        }
        dispatch(loadCalculation(calculation, organization, hasMarginTax))
        dispatch(updateManualReload(true))
    }, [calculations])

    useEffect(() => {
        if(dynamic == true){
            dispatch(toggleUseOverrideForAllPax(false))
            dispatch(updateManualReload(true))
        }else{
            dispatch(toggleUseOverrideForAllPax("default"))
            dispatch(updateManualReload(true))
        }
    }, [dynamic])


    return (
        <div className="panel-body">
            <div className="row">
                {dirty && (
                    <div className="col-xs-12">
                        <p className="alert alert-info">
                            <span className="fa fa-triangle-exclamation"></span>
                            {" "}
                            <strong>Achtung!</strong>
                            {" "}
                            Ungespeicherte Änderungen.
                        </p>
                    </div>
                )}
            </div>

            {notes &&
                notes.map(({
                   containerClassName,
                   message: {icon, attention, text},
                   workflow: {workflowId, label, buttonClassName, refetchOnResult} = {}
                }) => {
                return (
                    <div className="row">
                        <div className="col-xs-12">
                            <div className={containerClassName} style={{ display: 'flex', alignItems: "center"}}>
                                {icon && <><span className={icon}/>&nbsp;</>}
                                {attention && <strong>{ i18n.tr('tourism-journey-calculation.info.attention') }&#58;&nbsp;</strong>}
                                { text }
                                {workflowId && <Action
                                    disabled={isFetching || dirty}
                                    style={{marginLeft: 'auto'}}
                                    className={'rs-btn rs-btn-default rs-btn-xs '+buttonClassName}
                                    context={{
                                        id,
                                        workflowId
                                    }}
                                    handler={workflowActionHandler}
                                    onResult={refetchOnResult ? onNoteRefresh : null}
                                >
                                    { label }
                                </Action>}
                            </div>
                        </div>
                    </div>
                )})}

            <div className="row">
                {"entries" === view && (
                    <>
                        <div className="col-xs-12">
                            <ErrorBoundary>
                                <Parameters/>
                            </ErrorBoundary>
                        </div>

                        <div className="col-xs-12">
                            <ErrorBoundary>
                                <CalculationTable/>
                            </ErrorBoundary>
                        </div>
                    </>
                )}

                {"dates" === view && (
                    <ErrorBoundary>
                        <Dates/>
                    </ErrorBoundary>
                )}

                {"configurator" === view && (
                    <ErrorBoundary>
                        <Configurator/>
                    </ErrorBoundary>
                )}
            </div>

            {"configurator" !== view &&
                <div className="row" style={{marginTop: "3rem"}}>
                    <div className="col-xs-12 col-md-8 col-lg-9">
                        <textarea
                            className="form-control" rows="10"
                            disabled={isFetching}
                            value={note}
                            onChange={onChange}
                        />
                    </div>

                    <div className="col-xs-12 col-md-4 col-lg-3 text-right bottom-save">
                        {dirty && (
                            <p className="alert alert-info">
                                <span className="fa fa-triangle-exclamation"></span>
                                {" "}
                                <strong>Achtung!</strong>
                                {" "}
                                Ungespeicherte Änderungen.
                            </p>
                        )}

                        <SaveButton/>
                    </div>

                </div>
            }
        </div>
    )

    function onChange({target: {value}}) {
        dispatch(setField("note", value))
    }

    function onNoteRefresh() {
        refetch();
        dispatch(makeClean())
    }
}
